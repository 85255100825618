
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import baseInfo from '../components/base-info.vue'
import scoreTable from '../components/score-table.vue'
import { getCreditDetails, getBlackInfo, manualSave, switchPriority, creditRatingDrop, creditTaskAdd } from '@/api/creditRatingManagement'
@Component({
  components: {
    baseInfo,
    scoreTable
  }
})
export default class FarmerCreditJudgeDetails extends Vue {
  private loadingTxt: string = loadingText
  private nowId: any = {
    id: '',
    farmerId: ''
  }
  private isAssessOptions: any = []
  private creditRatingOptions: any = []
  private creditRatingDis: boolean = false
  private priorityDis: boolean = false
  private isEdit: boolean = false
  private proDetail: any = null // 信用评定数据
  // 个人信息
  private baseInfo: any = {}
  // 评分信息
  private rating: any = {}
  // 黑名单信息
  private black: any = null
  private loadings: boolean = false
  created() {
    this.loadings = true
    if (this.$route.params.id) {
      this.nowId.id = this.$route.params.id
      this.nowId.farmerId = this.$route.params.farmerId
      sessionStorage.setItem('farmerCreditJudgeDetails', JSON.stringify(this.nowId))
    } else {
      let nowIds: any = sessionStorage.getItem('farmerCreditJudgeDetails')
      nowIds = JSON.parse(nowIds)
      this.nowId.id = nowIds.id
      this.nowId.farmerId = nowIds.farmerId
    }
    creditRatingDrop({}).then((res) => {
      let datas: any = res.data.data
      if (datas) {
        datas.ratings && (this.creditRatingOptions = datas.ratings)
        datas.table && datas.table.credit_assessor_rel && datas.table.credit_assessor_rel.is_assess && (this.isAssessOptions = datas.table.credit_assessor_rel.is_assess)
      }
    })
    this.getInfo()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    if (to.name != 'FarmerInfoDetail') {
      sessionStorage.removeItem('farmerCreditJudgeDetails')
    }
    next()
  }
  private sumbitFn() {
    this.loadings = true
    creditTaskAdd({
      beAssessors: [this.nowId.id]
    })
      .then((res) => {
        this.getInfo()
      })
      .catch(() => {
        this.loadings = false
      })
  }
  private getInfo() {
    getCreditDetails({ id: this.nowId.id })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          if (datas.creditAssessor && datas.creditAssessor.isAssess != '1') {
            getBlackInfo({ beAssessorId: this.nowId.farmerId }).then((res) => {
              if (res.data.data) {
                this.black = res.data.data
              }
            })
          } else {
            datas.black && (this.black = datas.black)
          }
          if (datas.detail) {
            let isMainResult: boolean = false,
              isOtherResult: boolean = false,
              mainResult: any = 0,
              otherResult: any = 0,
              dotBit: number = 0
            if (datas.detail.mainResult) {
              for (let i = 0; i < datas.detail.mainResult.length; i++) {
                let score: any = datas.detail.mainResult[i].score
                if (score !== null) {
                  !isMainResult && (isMainResult = true)
                  let scoreStr: string = score + '',
                    nowDotBit: number = scoreStr.indexOf('.') > -1 ? scoreStr.slice(scoreStr.indexOf('.') + 1).length : 0
                  nowDotBit > dotBit && (dotBit = nowDotBit)
                  mainResult += score
                }
              }
            }
            if (datas.detail.otherResult) {
              for (let i = 0; i < datas.detail.otherResult.length; i++) {
                let score: any = datas.detail.otherResult[i].score
                if (score !== null) {
                  !isOtherResult && (isOtherResult = true)
                  let scoreStr: string = score + '',
                    nowDotBit: number = scoreStr.indexOf('.') > -1 ? scoreStr.slice(scoreStr.indexOf('.') + 1).length : 0
                  nowDotBit > dotBit && (dotBit = nowDotBit)
                  otherResult += score
                }
              }
            }
            if (isMainResult) {
              mainResult = Number(mainResult.toFixed(dotBit))
              this.rating.mainResult = mainResult
            }
            if (isOtherResult) {
              otherResult = Number(otherResult.toFixed(dotBit))
              this.rating.otherResult = otherResult
            }
            if (datas.creditAssessor && !datas.creditAssessor.totalScore && isMainResult && isOtherResult) {
              datas.creditAssessor.totalScore = Number((mainResult + otherResult).toFixed(dotBit))
            }
            this.proDetail = datas.detail
          }
          datas.creditAssessor && (this.baseInfo = datas.creditAssessor)
        }
      })
      .finally(() => {
        this.loadings = false
      })
  }
  private determineEdit(managerGrade: string) {
    if (managerGrade) {
      this.creditRatingDis = true
      manualSave({
        type: 1,
        idList: [this.nowId.id],
        name: managerGrade.split(',')[1],
        id: managerGrade.split(',')[0]
      })
        .then(() => {
          this.$message.success('修改管理员评定等级成功')
          this.baseInfo.creditRatingId = managerGrade.split(',')[0]
          this.baseInfo.creditRating = managerGrade.split(',')[1]
          this.isEdit = false
        })
        .finally(() => {
          this.creditRatingDis = false
        })
    }
  }
  private priorityEmit() {
    this.priorityDis = true
    let priority: any = this.baseInfo.priority == '1' ? '2' : '1'
    switchPriority({
      creditEFStatusRequests: [
        {
          id: this.nowId.id,
          priority: this.baseInfo.priority,
          creditRating: this.baseInfo.creditRating
        }
      ]
    })
      .then(() => {
        this.$message.success('切换成功')
        this.baseInfo.priority = priority
      })
      .finally(() => {
        this.priorityDis = false
      })
  }
}
